.content-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: var(--background);
    width: 100%;
    height: 50vh;
}

.info h1 {
    font-weight: 300;
    font-size: xx-large;
    margin-bottom: 5%;
}

.info span {
    font-weight: bold;
    color: var(--primary);
}

button.button-create {
    padding: 16px 32px;
    background-color: var(--primary);
    color: #ffffff;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 500;
}

button.button-create:hover {
    background-color: var(--secondary);
    cursor: pointer;
}

.content-ebalance-about {
    padding: 10%;
    width: 100%;
    height: 20vh;
    background-color: var(--primary);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.content-ebalance-about h2 {
    color: #ffffff;
    font-size: 36px;
}

.content-ebalance-about span {
    color: #ffffff;
    font-weight: 300;
}

.content-ebalance-utils {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 40vh;
}

.content-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.content-text h3 {
    color: var(--primary);
}

.content-icon img {
    height: 90px;
    width: auto;
}

.content-ebalance-functions {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    gap: 10%;
    background-color: var(--primary);
    height: 50vh;
    width: 100%;
}

.content-ebalance-functions h2 {
    font-size: 36px;
    color: #ffffff;
}

.content-function {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.content-item-function {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    color: #ffffff;
}


button.appointment-button {
    padding: 16px 32px;
    background-color: #ffffff;
    color: var(--primary);
    border: none;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 500;
}

button.appointment-button:hover {
    background-color: var(--secondary);
    color: #ffffff;
    cursor: pointer;
}

.content-ebalance-action {
    margin-top: 10px;
}

.footer-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 20vh;
    background-color: var(--secondary);
}

.footer-text {
    width: 100%;
    height: 4vh;
    color: #ffffff;
    background-color: var(--secondary);
    font-size: 12px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.content-private {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.content-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content-contact b {
    color: #ffffff;
}

a {
    color: #ffffff;
    text-decoration: none;
    font-size: 12px;
}